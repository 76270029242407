
  import userController from '/home/builduser/agent00/work/cd945eb1764b8543/src/components/ItemsList/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor-runtime/build/esm/controllerWrapper.js';
  import {
    createHttpClient,
  } from '@wix/yoshi-flow-editor-runtime/build/esm/controller/httpClientProp';
  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor-runtime/build/esm/i18next/init';



  var sentryConfig = {
    DSN: 'https://a4654045010646ebaa760c9a9fe2efbe@sentry.wixpress.com/844',
    id: 'a4654045010646ebaa760c9a9fe2efbe',
    projectName: 'yoshi-flow-editor-example',
    teamName: 'yoshi',
  };

  var experimentsConfig = {"scopes":["test"]};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/agent00/work/cd945eb1764b8543/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var biConfig = {"owner":"@wix/bi-logger-editor-flow-template","visitor":"@wix/bi-logger-editor-flow-template","enableUniversalEvents":false};

  var defaultTranslations = {"app.widget.welcome":"Welcome {greetingsText}","app.settings.label":"Configure Widget","app.widget.additionalInfo":"Info from editor script. Language is {lang}","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to","app.adi.settings.title":"Welcome to ADI Settings","app.adi.settings.description":"You can configure stuff","app.adi.settings.tabs.discussions.label":"Discussions","settings.adi.DesignPanel.useLayout":"Use current layour"};

  import biLogger from '/home/builduser/agent00/work/cd945eb1764b8543/node_modules/@wix/bi-logger-editor-flow-template/dist/src/index.js';

  const _controller = createControllerWrapper(userController, {
    sentryConfig,
    biConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    appName: "EditorFlowExample",
    appDefinitionId: "2f075c01-438e-4cac-a92a-162b4ab7a377",
    componentId: "912c75dc-5b5f-42ff-8f83-09c6e2a9b6e2",
    projectName: "yoshi-flow-editor-example",
    defaultTranslations,
    localeDistPath: "components/ItemsList/locales"
  }, {
    initI18n,
    createHttpClient,
  });
  export const controller = _controller;
  export default _controller;
